<template>
  <div class="g-section job-container">
    <div class="g-wrap job-top">
      <div class="flex">
        <!-- <span>筛选</span> -->
        <el-input placeholder="请输入关键字查询" suffix-icon="el-icon-search" v-model="keyword" size="small">
        </el-input>
      </div>
      <el-button type="primary" @click="$router.push('/school/recruit/create')" size="small" icon="el-icon-circle-plus-outline">发布招生简章</el-button>
    </div>
    <div class="g-wrap job-bottom">
      <div v-for="i in 6" class="job-item" :key="i">
        <div class="item-top">
          <div class="item-left">
            <span class="mb-10">招生简章{{i}}</span>
          </div>
          <div class="item-right">
            <div class="right-btn" @click="handleShowStudent"><span>6个</span><span>已报名</span></div>
            <!-- <div class="right-btn"><span>3位</span><span>已面试</span></div> -->
            <div class="right-btn"><span>2位</span><span>已通过</span></div>
          </div>
        </div>
        <div class="item-bottom">
          <div class="item-left">
            <span>发布时间：2020-12-31</span>
          </div>
          <div class="item-right">
            <el-button type="text" icon="el-icon-share" size="small">分享</el-button>
            <el-button type="text" icon="el-icon-refresh" size="small">刷新</el-button>
            <el-button type="text" icon="el-icon-circle-close" size="small">下线</el-button>
            <el-button type="text" icon="el-icon-edit" size="small">编辑</el-button>
            <el-button type="text" icon="el-icon-delete" size="small">删除</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      keyword: ''
    }
  },
  methods:{
    handleShowStudent(){
      this.$router.push('/school/recruit/studentlist')
    }
  }
}
</script>
<style lang="scss" scoped>
.job-container {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.job-top {
  justify-content: space-between;
  margin-top: 20px;
}
.job-bottom {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.job-item {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
  border: 1px solid #e7e8ea;
  border-radius: 3px;
  .item-top {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    .item-left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
    }
    .item-right {
      display: flex;
      align-items: center;
      .right-btn {
        cursor: pointer;
        margin-left: 20px;
        display: flex;
        flex-direction: column;
      }
    }
  }
  .item-bottom {

    justify-content: space-between;
    display: flex;
    border-top: 1px solid #e7e8ea;
    background: #fbfbfb;
    height: 32px;
    align-items: center; 
    padding: 0px 10px;
  }
  .el-button {
    padding: 0px;
  }
}
</style>